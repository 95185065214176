<template>
    <defaultSec :title="'素材审核'" >

    <materialDetail ref="materialDetail" />
    </defaultSec>
</template>
<script>
import materialDetail from "@c/scrm/materialDetail";
import defaultSec from '@c/defaultSection'

export default {
    data(){
        return{
            mCode:this.$route.params.id||''
        }
    },
    components:{
        materialDetail,defaultSec
    },
    mounted() {
        console.log(3,this.$route.params.id)
        this.$refs.materialDetail.getSingle(this.mCode,1)
    }
}
</script>